import React from "react";
import Logo from "../logo.png";

const Header = () => {
  return (
    <div
      className="p-3 flex flex-row items-center gap-5 pl-5 header xl:bg-white"
      data-aos="fade-dow"
    >
      <div className="xl:hidden" onClick={()=>{
        if(document.getElementById('icon').classList.contains('fa-bars')){
          document.getElementById('topbar').style.height = "auto";
          document.getElementById('icon').classList.remove('fa-bars');
          document.getElementById('icon').classList.add("fa-close");
        }else{
          document.getElementById('topbar').style.height = "0px";
          document.getElementById('icon').classList.remove('fa-close');
          document.getElementById('icon').classList.add("fa-bars");
        }
      }}>
        <i class="fa-solid fa-bars cursor-pointer" id="icon"></i>
      </div>
      <img src={Logo} alt="logo" className="xl:h-20 h-12 mx-auto" />
      <div className="w-full xl:block hidden">
        <ul className="playfair flex flex-row justify-evenly w-full header-ul cursor-pointer items-center">
          <a href="/">
            <li className="border-r-2 pr-10">Home</li>
          </a>
          <a href="/about">
            <li className="border-r-2 pr-10">About Us</li>
          </a>
          <a href="/services">
            <li className="border-r-2 pr-10">Services</li>
          </a>
          <a href="/testimonials"><li className="border-r-2 pr-10">Testimonials</li></a>
          <a href="/gallery">
            <li className="border-r-2 pr-10">Media & Gallery</li>
          </a>
          <a href="/contact">
            <li className="border-r-2 pr-10">Contact Us</li>
          </a>
          <a href="/privacy-policy">
            <li className="border-r-2 pr-10">Privacy Policy</li>
          </a>
          <a href="/terms-and-conditions">
            <li className="border-r-2 pr-10">Terms and Conditions</li>
          </a>
          <a href="/login">
            <i className="fa-solid fa-user"></i>
          </a>
        </ul>
      </div>
    </div>
  );
};

export default Header;
