import React from "react";

const Topbar = () =>{
    return(
        <div className="w-full bg-white md:hidden p-4 overflow-hidden h-0" id="topbar" style={{
            transition:"1s all ease-in-out"
        }}>
            <a href="/"><h1 className="p-2">Home</h1></a>
            <hr />
            <a href="/about"><h1 className="p-2">About us</h1></a>
            <hr />
            <a href="/services"><h1 className="p-2">Services</h1></a>
            <hr />
            <h1 className="p-2">Testimonials</h1>
            <hr />
            <a href="/gallery"><h1 className="p-2">Media & Gallery</h1></a>
            <hr />
            <a href="/contact"><h1 className="p-2">Contact us</h1></a>
            <hr />
            <a href="/policy"><h1 className="p-2">Privacy Policy</h1></a>
            <hr />
            <a href="/terms"><h1 className="p-2">Terms & Conditions</h1></a>
            <hr />
        </div>
    );
}

export default Topbar;