import axios from 'axios';
import React, { useEffect, useState } from 'react'
import BASE from '../api/api';
import person from "../Assets/person.jpg";


const Testimonials = () => {

    const [reviews, setReviews] = useState([]);

    const FetchReviews = async () => {
        axios.get(`${BASE}/review/reviews`).then(resp => {
            if (resp.data.success) {
                setReviews(resp.data.reviews);
            } else {
                alert(resp.data.message);
            }
        }).catch(err => {
            alert(err.message);
        })
    }

    useEffect(() => {
        FetchReviews();
    }, []);

    return (
        <div>
            <h1 className='text-center text-2xl m-10'>Testimonials</h1>
           <div className='grid md:grid-cols-2 gap-3 p-4'>
            {
                    reviews && reviews.map(review => {
                        return (
                            <div className="flex flex-col justify-center items-center gap-4 border border-gray-300 p-2 rounded-lg">
                                <p className="text-xl playfair-italic">
                                    {review.Message}
                                </p>
                                <p className="text-xl playfair-italic">Read more</p>
                                <img src={person} alt="" className="w-16 h-16" />
                                <h1>{review.Name}</h1>
                            </div>
                        );
                    })
                }
           </div>
        </div>
    )
}

export default Testimonials;